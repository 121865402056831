<template>
  <div class="wih100 ht100 cpt-cesium_layerBox">
    <div
      v-for="item in totalMaps"
      :key="item"
      :class="[
        { [layerMapClass]: true },
        { [`cpt-cesium_layer_${item}`]: true },
        { [`cpt-cesium_layer_${item}--iframe`]: isIframe },
      ]"
    >
      <div
        :id="`layer-container_${item}`"
        :style="getContainerStyle(totalMaps, item)"
      >
        <!-- 分屏模式时候 选择控制哪块地图实例 -->
        <div
          v-if="totalMaps != 1"
          class="pa top312 cp"
          :class="{ right0: totalMaps != 3, right_33: totalMaps == 3 }"
          style="z-index: 19"
          @click="selectMpa(item)"
        >
          <el-radio v-model="radio" :label="item" title="选择分屏">
            <span style="display: none">分屏{{ item }}</span>
          </el-radio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let viewer = null;
import { mapState, mapActions } from "vuex";
import { User } from "@/api";
export default {
  data() {
    return {
      // totalMaps: 2,
      init_lng: 112.576667,
      init_lat: 23.175833,
      init_alt: 5000,
      radio: 1,
      layerMapClass: "",
      imageryLayers: {
        night: null, // 夜景图
        satellite: null, // 卫星图
        text: null, // 地名标记
      },
    };
  },
  computed: {
    ...mapState("subplatform", ["mapStyle", "totalMaps"]),
    ...mapState(["mapMode", "isIframe"]),
  },
  watch: {
    "$route.path"(newPath, oldPath) {
      // if(newPath !='/home'){
      viewer.scene.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          this.init_lng,
          this.init_lat,
          this.init_alt
        ),
      });
      // }
    },
    radio: {
      handler(value) {
        this.$store.commit("uavApplications/setState", {
          key: "mapRadio",
          value: value,
        });
      },
    },
    mapStyle: {
      handler(value) {
        this.layerMapClass = value;
      },
      deep: true,
      immediate: true,
    },
    mapMode(newVal) {
      switch (newVal) {
        case 0:
          this.imageryLayers.satellite.show = true;
          this.imageryLayers.night.show = false;
          this.imageryLayers.text.show = true;
          break;

        case 1:
          this.imageryLayers.satellite.show = false;
          this.imageryLayers.night.show = true;
          this.imageryLayers.text.show = false;
          break;
      }
    },
    totalMaps(newVal) {
      if (newVal) {
        const viewersLength = Object.keys(window.viewers).length;
        if (newVal > viewersLength) {
          const keysToAdd = [];
          for (let i = viewersLength + 1; i < newVal + 1; i++) {
            const newKey = `${i}`;
            setTimeout(() => {
              this.init_viewer(newKey);
            }, 10);
          }
        } else if (newVal < viewersLength) {
          const keysToDelete = Object.keys(window.viewers).slice(newVal);
          keysToDelete.forEach((key) => {
            delete window.viewers[key];
          });
        }
        // 视角 跟随
        setTimeout(() => {
          for (let i = 1; i < newVal + 1; i++) {
            const map = window.viewers[i];
            map.camera.changed.addEventListener(
              ((index) => {
                return () => {
                  this.handleCameraChange(index, map.camera);
                };
              })(i)
            );
          }
        }, 1000);
      }
    },
  },
  mounted() {
    window.viewers = {};
    // this.get_user_position()//获取用户位置
    this.initModel();
    this.interceptor();
    this.bus_getPositions();
    this.showAllArea(window.viewer);
  },
  methods: {
    ...mapActions(["showAllArea"]),
    initModel() {
      for (let i = 0; i < this.totalMaps; i++) {
        this.init_viewer(i + 1);
      }
      // 添加右键拖动地图功能
      if (window.viewer) {
        this.addCameraRotateByRightDrag(window.viewer);
      }
    },

    interceptor() {
      let count = 0;
      // 拦截请求
      const oldOpen = XMLHttpRequest.prototype.open;
      XMLHttpRequest.prototype.open = function (method, url) {
        // 地图瓦片调用拦截
        if (
          url.includes(
            "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/"
          ) ||
          url.includes(
            "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/"
          )
        ) {
          count += 1;
        }
        // 调用原生方法
        oldOpen.apply(this, arguments);
      };
      // 监听瓦片是否加载完成
      window.viewer.scene.globe.tileLoadProgressEvent.addEventListener(
        function (tile) {
          if (tile === 0) {
            User.setMapCount({ param: count }).then((res) => {
              if (res.code === 200) {
                count = 0;
              }
            });
          }
        }
      );
    },
    selectMpa(item) {
      console.log(item, "item");
    },
    handleCameraChange(mapId, camera) {
      // 遍历所有地图实例
      for (let i = 1; i < this.totalMaps + 1; i++) {
        if (mapId !== i) {
          const map = window.viewers[i];
          // 将其他地图的视角设置为与改变的地图相同
          map.camera.setView({
            destination: camera.position.clone(),
            orientation: {
              heading: camera.heading,
              pitch: camera.pitch,
              roll: camera.roll,
            },
          });
        }
      }
    },
    getContainerStyle(totalMaps, item) {
      const baseStyle = {
        position: "absolute",
        left: 0,
        bottom: 0,
        top: 0,
        right: 0,
        height: "100%",
        boxSizing: "border-box",
      };

      const width = totalMaps === 1 ? "100%" : "50%";
      const height = totalMaps === 4 ? "50%" : "100%";
      const left =
        totalMaps === 3
          ? `${(item - 1) * 33.33}%`
          : item % 2 === 0
          ? "50%"
          : "0";
      const top = totalMaps === 4 && item > 2 ? "50%" : "0";
      return {
        ...baseStyle,
        width,
        height,
        left,
        top,
      };
    },
    bus_getPositions() {
      this.$bus.$on("handlePositions", (e) => {
        this.init_lng = e[0];
        this.init_lat = e[1];
        this.init_alt = 300000;
        viewer.scene.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            this.init_lng,
            this.init_lat,
            this.init_alt
          ),
        });
      });
    },
    /**
     * 添加鼠标右键拖动相机旋转功能
     * @param {Cesium.Viewer} viewer - Cesium 查看器实例
     */
    addCameraRotateByRightDrag(viewer) {
      // 检查是否有 Cesium Viewer 实例
      if (viewer) {
        // 创建一个新的事件处理器,用于处理屏幕空间事件
        const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
        //viewer.scene.screenSpaceCameraController.enableTilt = false;
        // 标记右键是否被按下
        let isRightDown = false;
        // 记录上一次鼠标位置
        let lastPosition;
        // 计算缩放比例的系数
        let scale = 0;

        // 监听右键按下事件
        handler.setInputAction((movement) => {
          // 标记右键已经按下
          isRightDown = true;
          // 记录当前鼠标位置
          lastPosition = Cesium.Cartesian3.clone(movement.position);
          // 获取相机的高度
          const height = viewer.camera.positionCartographic.height;
          // 获取 Canvas 的尺寸
          const canvas = viewer.scene.canvas;
          // 计算缩放比例的系数,使用经验公式 (height * 0.5) / (canvas.clientHeight * 6371000)
          scale = (height * 0.5) / (canvas.clientHeight * 6371000);
        }, Cesium.ScreenSpaceEventType.RIGHT_DOWN);

        // 监听右键释放事件
        handler.setInputAction((movement) => {
          // 标记右键已经释放
          isRightDown = false;
        }, Cesium.ScreenSpaceEventType.RIGHT_UP);

        // 监听鼠标移动事件
        handler.setInputAction((movement) => {
          // 如果右键按下
          if (isRightDown) {
            // 计算鼠标位置的变化量
            const dx = movement.endPosition.x - lastPosition.x;
            const dy = movement.endPosition.y - lastPosition.y;
            // 根据变化量和缩放比例计算相机旋转角度
            const deltaPhi = dx * scale;
            const deltaTheta = dy * scale;
            // 旋转相机
            viewer.camera.rotateRight(-deltaPhi);
            viewer.camera.rotateUp(-deltaTheta);
            // 更新上一次的鼠标位置
            lastPosition = Cesium.Cartesian3.clone(movement.endPosition);
          }
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      }
    },
    async init_viewer(item) {
      // 超图服务加密访问
      // Ap6qLhnKi1YT4ds90jxn6mftBG4tmLFbTH2Y79XRGMG4fophoB8axKlCj6IuQMW8dAXsyHAnmzrU-zF1tMuMpg..
      // Cesium.Credential.CREDENTIAL = new Cesium.Credential('r0G6d8tdvfgN_Aad1j28SGqj6ILgt1DNHpP9dK-FNVOVBPgcyaHiEs2z2qyv455bTHU8QaFTbt_Y_AdeAsPp-Q..');
      // 解决航线被三维模型遮盖
      const oldPolylineUpdate = Cesium.PolylineCollection.prototype.update;
      Cesium.PolylineCollection.prototype.update = function (frameState) {
        const oldMorphTime = frameState.morphTime;
        frameState.morphTime = 0.0;
        oldPolylineUpdate.call(this, frameState);
        frameState.morphTime = oldMorphTime;
      };
      const viewerOptions = {
        animation: false,
        shouldAnimate: true,
        homeButton: false,
        fullscreenButton: false,
        baseLayerPicker: false,
        geocoder: true,
        timeline: false,
        sceneModePicker: true,
        navigationHelpButton: false,
        infoBox: false,
        requestRenderMode: true,
        scene3DOnly: false,
        sceneMode: 3,
        fullscreenElement: document.body,
        selectionIndicator: false,
      };

      if (item === 1) {
        window.viewer = viewer = new Cesium.Viewer(
          `layer-container_${item}`,
          viewerOptions
        );
        window.viewers[item] = viewer;
      } else {
        const viewer = new Cesium.Viewer(
          `layer-container_${item}`,
          viewerOptions
        );
        viewer.scene.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            this.init_lng,
            this.init_lat,
            this.init_alt
          ),
        });
        viewer.imageryLayers.addImageryProvider(
          new Cesium.UrlTemplateImageryProvider({
            url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          })
        );
        window.viewers[item] = viewer;
      }

      this.imageryLayers.satellite = viewer.imageryLayers.addImageryProvider(
        new Cesium.UrlTemplateImageryProvider({
          url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          maximumLevel: 18,
        })
      );
      this.imageryLayers.night = viewer.imageryLayers.addImageryProvider(
        new Cesium.UrlTemplateImageryProvider({
          url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
          maximumLevel: 16,
        })
      );
      this.imageryLayers.text = viewer.imageryLayers.addImageryProvider(
        new Cesium.WebMapTileServiceImageryProvider({
          url: "https://t0.tianditu.gov.cn/cia_w/wmts?tk=ced06243e56f41f5b7180f08731026da",
          // url: "https://{s}.tianditu.gov.cn/cia_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=ced06243e56f41f5b7180f08731026da",
          layer: "cia",
          style: "default",
          tileMatrixSetID: "w",
          format: "tiles",
          maximumLevel: 18,
        })
      );
      this.imageryLayers.night.show = false;
      this.imageryLayers.text.show = true;

      if (item == 1) {
        viewer.scene.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            this.init_lng,
            this.init_lat,
            this.init_alt
          ),
        });
      }

      // 加载三维倾斜
      window._3d = [];
      /* const token =
        'r0G6d8tdvfgN_Aad1j28SGqj6ILgt1DNHpP9dK-FNVOVBPgcyaHiEs2z2qyv455bTHU8QaFTbt_Y_AdeAsPp-Q..'; */
      window.___s3m.forEach(async (item) => {
        const obj = await viewer.scene.addS3MTilesLayerByScp(
          `https://ct.mmcuav.cn:8443/iserver/services/${item}/rest/realspace/datas/Config/config`,
          {
            name: item,
            // cullEnabled: false
          }
        );
        window._3d.push(obj);
        if (item === "3D-ZQ-JLH") {
          // 肇庆九龙湖的模型需要修改海拔, 使其贴地
          obj.style3D.bottomAltitude = -20;
        }
      });

      // 二维影像缓存加载方式
      window._2d = [];
      ["3D-ZQ-DH", "3D-local3DCache-s3mb"].forEach((item) => {
        viewer.scene
          .open(
            `https://ct.mmcuav.cn:8443/iserver/services/${item}/rest/realspace`,
            undefined,
            {
              subdomains: [""], // 子域
              autoSetView: false, // 不自动定位
            }
          )
          .then((obj) => {
            window._2d.push(obj);
          });
      });

      // 关闭地图深度检测
      viewer.scene.globe.depthTestAgainstTerrain = false;
      // 飞向指定的经纬度
      window.flyTo = function (lng, lat) {
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(lng, lat, 1000),
        });
      };
    },
    add_polyline(entities, data) {
      return entities.add({
        id: data.entity_id,
        parent: data.parent || null,
        polyline: {
          positions: new Cesium.CallbackProperty(() => {
            return data.positions;
          }, false),
          width: 5,
          material: Cesium.Color.fromCssColorString("#6DD400"),
          show: true,
          ...(data.options ? data.options : {}),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cesium_layerBox {
}

.cpt-cesium_layer_1 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;

  // #layer-container_1 {
  //   position: absolute;
  //   left: 0;
  //   bottom: 0;
  //   top: 0;
  //   right: 0;
  //   width: 100%;
  //   height: 100%;
  //   box-sizing: border-box;
  // }
}

.videoWallMapStyle {
  position: fixed !important;
  left: 12px !important;
  top: 63px !important;
  bottom: 0 !important;
  width: 415px !important;
  height: 234px !important;
  box-sizing: border-box !important;
  z-index: 1;
}

.videoWallMapStyle--iframe {
  top: 19px !important;
}
.right_33 {
  right: 33%;
}
</style>
